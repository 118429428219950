<template>
    <div>
        <div class="row mb-3">
            <div class="col-12 col-md-4">
                <dashboard-applications-status/>
                <dashboard-top-users/>
            </div>
            <div class="col-12 col-md-8">
                <dashboard-registred-applications :isStaff="isStaff"/>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <dashboard-recent-applications :isStaff="isStaff"/>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col-12">
                <dashboard-recent-issued-doc/>
            </div>
        </div>
    </div>
</template>

<script>
import DashboardApplicationsStatus from '../components/DashboardApplicationsStatus.vue';
import DashboardRecentApplications from '../components/recent-applications/DashboardRecentApplications.vue';
import DashboardRecentIssuedDoc from '../components/recent-issued-doc/DashboardRecentIssuedDoc.vue';
import DashboardRegistredApplications from '../components/registred-applications/DashboardRegistredApplications.vue';
import DashboardTopUsers from '../components/DashboardTopUsers.vue';

export default {
    name: 'DashboardLegalEntity',
    components: {
        DashboardApplicationsStatus,
        DashboardRecentApplications,
        DashboardRecentIssuedDoc,
        DashboardRegistredApplications,
        DashboardTopUsers,
    },
    props: {
        isStaff: {
            type: Boolean,
            required: true
        },
    },
}
</script>
