<template>
    <div class="ml-2">
        <div class="card-header pl-2 border-0 pb-2">
            <div class="d-flex justify-content-between w-100">
                <div>
                    <p class="font-size-lg font-weight-bold mb-0">{{ $t('DASHBOARD.REGISTRED_APPLICATIONS.HEADER') }}</p>
                    <small>{{ $t('DASHBOARD.REGISTRED_APPLICATIONS.DESCRIPTION') }}</small>
                </div>
                <div>
                    <span class="label label-xl label-inline label-light-dark">
                            {{ $t('DASHBOARD.REGISTRED_APPLICATIONS.PERIOD') }}
                    </span>
                    <span class="ml-4"><i class="fas fa-ellipsis-h font-size-h4"></i></span>
                </div>
            </div>
        </div>
        <div class="my-6">
            <div id="chart">
                <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
            </div>
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    name: "RegisteredApplications",
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            series: [{
                name: 'Solutionate',
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
            }, {
                name: 'Depuse',
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            }],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                },
                yaxis: {
                    title: {
                        text: 'Numar cereri'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return val
                        }
                    }
                },
                legend: {
                   horizontalAlign: 'left',
                }

            }

        };
    },
}
</script>
