<template>
    <b-card class="st-section mt-6">
        <div class="st-section-header">
            <div class="d-flex justify-content-between w-100">
                <p>{{ $t('DASHBOARD.APPLICATIONS_STATUS.HEADER') }}</p>
                <div>
                    <span class="label label-xl label-inline label-light-dark">
                        {{ $t('DASHBOARD.APPLICATIONS_STATUS.PERIOD') }}
                    </span>
                    <span class="ml-4"><i class="fas fa-ellipsis-h font-size-h4"></i></span>
                </div>
            </div>
        </div>
        <div class="st-section-body">
           <div id="chart">
                <apexchart type="donut" width="300" :options="optionsTerm" :series="seriesTerm"></apexchart>
            </div>
        </div>
    </b-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    name: 'Dashboard',
    components: {
        apexchart: VueApexCharts,
    },
    data() {
        return {
            seriesTerm: [15, 75, 8, 3],
            optionsTerm: {
                labels: ['Solutionate', 'Inregistrate', 'In Procesare', 'Clarificari'],
                dataLabels: {
                    enabled: false,
                    position: 'bottom'
                },
                legend: {
                    position: 'right'
                },
            },
        };
    },
    computed: {

    },
    methods: {

    },
}
</script>
