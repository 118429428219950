<template>
    <b-card no-body class="mt-6 st-section">
        <div class="row">
            <div class="col-12 col-md-8 border-right" :style="style">
                <registered-applications />
            </div>
            <div class="col-12 col-md-4 pl-0">
                <solved-applications :isStaff="isStaff" />
            </div>
        </div>
    </b-card>
</template>

<script>
import RegisteredApplications from './RegisteredApplications';
import SolvedApplications from './SolvedApplications';

export default {
    name: 'DashboardRegistredApplications',
    components: {
        RegisteredApplications,
        SolvedApplications,
    },
    props: {
        isStaff: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        style() {
            return {
            }
        }
    }
}
</script>
