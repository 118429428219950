<template>
    <div>
        <div class="border-bottom pl-4">
            <div class="card-header pl-2 border-0 pb-2">
                <p class="font-size-lg font-weight-bold mb-0">{{ $t('DASHBOARD.SOLVED_APPLICATIONS.HEADER') }}</p>
                <small>{{ $t('DASHBOARD.SOLVED_APPLICATIONS.DESCRIPTION') }}</small>
            </div>
            <div class="my-4 mb-6 mx-2 pr-2">
                <span class="font-weight-bolder font-size-lg text-primary">
                    {{totalApplicationsSolved}}%
                </span>
                <div class="progress progress-sm mt-2">
                    <div
                        class="progress-bar bg-primary"
                        role="progressbar"
                        :style="style"
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
            </div>
        </div>
        <div class=" pl-4">
            <div class="card-header pl-2 border-0 pb-2">
                <p class="font-size-lg font-weight-bold mb-4">
                    {{title }}
                </p>
                <template v-for="(item, i) in solvedApplicationsContent">
                    <div
                        class="d-flex justify-content-between mb-4"
                        :key="i"
                    >
                        <p class="font-weight-normal font-size-lg text-dark-75  mb-1">
                            {{ item.text }}
                        </p>
                        <p class="font-size-lg text-primary py-1 my-lg-0 my-2 ml-6">
                            {{ item.value }}
                        </p>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SolvedApplications",
    props: {
        isStaff: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            totalApplicationsSolved: 75,
            topApplicationsTypes: [
                {
                    text: "Cereri emitere Certificat de Urbanism",
                    value: "128",
                },
                {
                    text: "Cereri prelungire Certificat de Urbanism",
                    value: "114",
                },
                {
                    text: "Cerere aviz primar",
                    value: "98",
                },
                {
                    text: "Cerere autorizatie foraje si excavari",
                    value: "36",
                },
                {
                    text: "Cerere pentru emiterea Avizului de oportunitate",
                    value: "35",
                },
            ],
            applicationsOnTerritorialUnit: [
                {
                    text: "Cluj-Napoca",
                    value: "128",
                },
                {
                    text: "Floresti",
                    value: "114",
                },
                {
                    text: "Apahida",
                    value: "98",
                },
                {
                    text: "Baciu",
                    value: "36",
                },
                {
                    text: "Dej",
                    value: "35",
                },
            ],
        };
    },
    computed: {
        style() {
            return {
                width: `${this.totalApplicationsSolved}%`
            }
        },
        title() {
            return this.isStaff ? this.$t('DASHBOARD.SOLVED_APPLICATIONS.APPLICATIONS_TYPE_HEADER_STAFF') :
                                  this.$t('DASHBOARD.SOLVED_APPLICATIONS.APPLICATIONS_TYPE_HEADER_LEGAL')
        },
        solvedApplicationsContent() {
             return this.isStaff ? this.applicationsOnTerritorialUnit : this.topApplicationsTypes;

        }
    },
}
</script>
