<template>
    <st-page customClass="pb-8 mt-2" :showHeader="false">
        <!-- TODO: Uncomment after permissions are set for issued documents -->
        <!-- <recent-issued-doc-list-filter>
            <recent-issued-doc-list-table/>
        </recent-issued-doc-list-filter> -->
    </st-page>
</template>
<script>
// import RecentIssuedDocListFilter from './RecentIssuedDocListFilter';
// import RecentIssuedDocListTable from './RecentIssuedDocListTable';

export default {
    name: 'DashboardRecentIssuedDoc',
    components: {
        // RecentIssuedDocListFilter,
        // RecentIssuedDocListTable,
    },
}
</script>
